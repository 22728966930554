export const addressFields: { [key: string]: string } = {
  country: 'countryCode',
  address1: 'streetLine1',
  address2: 'streetLine2',
  city: 'city',
  province: 'state',
  zip: 'postalCode'
}

export const labelsMap: { [key: string]: string } = {
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  country: 'country',
  province: 'zone',
  zip: 'postalCode'
}

export const autoCompleteMap: { [key: string]: string } = {
  streetLine1: 'address-line1',
  streetLine2: 'address-line2',
  city: 'address-level2',
  state: 'address-level1',
  postalCode: 'postal-code'
};

export const fieldTypeMap: { [key: string]: string } = {
  countryCode: 'select',
  streetLine1: 'text',
  streetLine2: 'text',
  city: 'text',
  state: 'select',
  postalCode: 'text'
};

// Shopify provides subdivisions for these countries, but we don't have them in our configs
// TO DO: Remove this when we use Shopify zones for subdivisions select input
export const COUNTRIES_WITHOUT_SUBDIVISION = [
  'IE',
  'NZ',
  'PT'
]

export const COUNTRIES_WITHOUT_POSTAL_CODE = [
  'AE'
]

export const POSTAL_CODE: { [key: string]: RegExp } = {
  // Argentina
  // format: 1974-1998 NNNN; From 1999 ANNNNAAA
  AR: /^\d{4}|[A-Za-z]\d{4}[a-zA-Z]{3}$/,
  // Australia
  // https://regex101.com/library/aK6aF5
  AU: /^\d{4,4}$/,
  // Austria
  // https://regex101.com/library/aK6aF5
  AT: /^[1-9]{1}[0-9]{3}$/i,
  // Belgium
  // Must be exactly 4 digits
  BE: /^\d{4}$/,
  // Bulgaria
  // Must be exactly 4 digits
  BG: /^\d{4}$/,
  // Canada
  CA: /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i,
  // Colombia
  // format: 99999, 99( )999 - specifics: All postal codes commence with a digit between 1 and 5
  CO: /^((05|08|11|13|15|17|18|19|20|23|25|27|41|44|47|50|52|54|63|66|68|70|73|76|81|85|86|88|91|94|95|97|99)\d{4,4})/i,
  // Croatia
  // https://www.grcdi.nl/gsb/croatia.html
  // format: 99999, 99( )999
  // specifics: All postal codes commence with a digit between 1 and 5
  HR: /^[1-5]{1}\d{1}\s?\d{3}$/i,
  // Cyprus
  // https://www.grcdi.nl/gsb/cyprus.html
  // format: 9999
  // specifics: First digit may not be 0
  CY: /^[1-9]{1}\d{3}$/i,
  // Czechia
  CZ: /^\d{3}\s\d{2}$/,
  // Denmark
  DK: /^\d{4,4}$/i,
  // Dominican Republic
  DO: /^\d{5}$/i,
  // Estonia
  // https://www.grcdi.nl/gsb/estonia.html
  // format: 99999
  // specifics: First digit may not be 0
  EE: /^[1-9]{1}\d{4}$/i,
  // Finland
  // https://www.grcdi.nl/gsb/finland.html
  // format: 99999
  FI: /^\d{5}$/i,
  // France
  // https://rgxdb.com/r/354H8M0X
  FR: /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/i,
  // Germany
  // https://rgxdb.com/r/373ICO02
  DE: /^\d{5}$/i,
  // Greece
  GR: /^[0-9]{3}(\s)?[0-9]{2}$/i,
  // Hungary
  // Must be exactly 4 digits
  HU: /^\d{4}$/,
  // India
  IN: /^[1-9]\d{5,5}$/,
  // Indonesia
  ID: /^[1-9]\d{4,4}$/i,
  // Ireland
  // https://regex101.com/library/mS4fO6
  IE: /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/gi,
  // Israel
  // https://www.grcdi.nl/gsb/israel.html
  IL: /^\d{7,7}$/i,
  // Italy
  IT: /^[0-9]{5}$/i,
  // Latvia
  // https://www.grcdi.nl/gsb/latvia.html
  // format: 9999 | LV-9999
  // specifics: Postal codes do not begin with 0, 22-29, 35, 49, 55 or 58-99.
  LV: /^(LV-)?((1)[0-9]{3}|(2)(1)[0-9]{2}|(3)([0-4]|[6-9])[0-9]{2}|(4)[0-8][0-9]{2}|(5)([0-4]|[6-7])[0-9]{2})$/i,
  // Lithuania
  // https://www.grcdi.nl/gsb/lithuania.html
  // format: 99999
  LT: /^\d{5}$/i,
  // Luxembourg
  // https://www.grcdi.nl/gsb/luxembourg.html
  // format: 9999 | L-9999
  // specifics: The first digits may not be 0, 30-31, 50-51, 60, 70-71, 78-79, 89.
  LU: /^(L-)?((1|2|4|9)[0-9]{3}|(3|5)[2-9][0-9]{2}|(6)[1-9][0-9]{2}|(7)[2-7][0-9]{2}|(8)[0-8][0-9]{2})$/i,
  // Malta
  // https://www.grcdi.nl/gsb/malta.html
  // format: AAA 9999 | AA 99
  MT: /^([a-zA-Z]{3} \d{4})|([a-zA-Z]{2} \d{2})$/i,
  // Mexico
  MX: /^\d{5}$/,
  // Netherlands
  // https://stackoverflow.com/a/17898538
  NL: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
  // New Zealand
  // https://regex101.com/library/aK6aF5
  NZ: /^\d{4,4}$/,
  // Norway
  NO: /^\d{4}$/,
  // Philippines
  PH: /^\d{4}$/,
  // Poland
  // Five digits with a dash between 2nd and 3rd chars
  PL: /^[0-9]{2}-[0-9]{3}/,
  // Portugal
  // Stripe requires a Portuguese Postal Code to be the format XXXX-XXX
  PT: /^[1-9]{1}[0-9]{3}-{1}[0-9]{3}$/,
  // Slovakia
  // https://www.grcdi.nl/gsb/slovakia.html
  // format: 999 99
  // specifics: All postal codes commence with 0, 8 or 9.
  SK: /^(0|8|9)\d{2} \d{2}$/i,
  // Slovenia
  // https://www.grcdi.nl/gsb/Slovenia.html
  // format: 9999
  // specifics: Postal codes cannot commence with 0 or 7.
  SI: /^([1-6]|8|9)\d{3}$/i,
  // South Africa
  // format: 9999
  // specifics: Postal codes are 4 digits.
  ZA: /^\d{4,4}$/,
  // Spain
  ES: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
  // Sweden
  // https://www.grcdi.nl/gsb/Sweden.html
  SE: /^([1-2|5-8]\d{2,2}( )\d{2,2}|(3)[0-1|3-9][0-9]( )\d{2,2}|(4)[0-7][0-9]( )\d{2,2}|(9)[0-8][0-9]( )\d{2,2})/i,
  // Taiwan
  TW: /^(\d{3,3}|\d{6,6}|\d{3,3}(-)\d{3,3})$/,
  // Thailand
  TH: /^([1][0-8]\d{3,3}|[2][0-7]\d{3,3}|[3][0-7,9]\d{3,3}|[4][0-9]\d{3,3}|[5][0-8]\d{3,3}|[6][0-7]\d{3,3}|[7][0-9]\d{3,3}|[8][0-6]\d{3,3}|[9][0-6]\d{3,3})/i,
  // United Kingdom
  // https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
  GB: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i,
  // Uruguay
  // https://www.grcdi.nl/gsb/uruguay.html
  UY: /^([1-9]\d{4,4})$/i
}
