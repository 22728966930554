import { gql } from '@apollo/client';

export type AddressFieldType = {
  name: string;
  label: string;
  required: boolean;
  autoComplete?: string;
}

export type AddressFormType = {
  fields: AddressFieldType[]
}

export type QueryAddressFormResType = {
  addressForm: AddressFormType
}

export const GET_ADDRESS_FORM_QUERY_NAME = 'addressForm';

export const QUERY_ADDRESS_FORM = gql`
  query ${GET_ADDRESS_FORM_QUERY_NAME} ($countryCode: String!) {
    ${GET_ADDRESS_FORM_QUERY_NAME} (countryCode: $countryCode) {
      fields
    }
  }
`
