import { useEffect, useState } from 'react';
import AddressFormatter, { Country, FieldName } from '@shopify/address';
import { useTranslation } from 'react-i18next';
import { GET_ADDRESS_FORM_QUERY_NAME, QUERY_ADDRESS_FORM, QueryAddressFormResType } from 'graphql/queries/AddressFormQueries';
import useQueryWithErrors from 'hooks/useQueryWithErrors';

export const useAddressFormatter = (countryCode: string) => {
  const [country, setCountry] = useState<Country | null>(null);
  const [formatter, setFormatter] = useState<AddressFormatter | null>(null);
  const [orderedFields, setOrderedFields] = useState<FieldName[][] | null>(
    null
  );
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const customFieldCountries: string[] = ['AR']
  const hasCustomFields = customFieldCountries.includes(countryCode)

  const { data: addressFormData } = useQueryWithErrors<QueryAddressFormResType>(
    GET_ADDRESS_FORM_QUERY_NAME,
    QUERY_ADDRESS_FORM,
    {
      skip: !countryCode || !hasCustomFields,
      variables: { countryCode },
      fetchPolicy: 'no-cache'
    }
  )

  const customOrderedFields = addressFormData?.addressForm?.fields

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const formatter = new AddressFormatter(locale);
        const countryReturn = await formatter.getCountry(countryCode);
        const fields = await formatter.getOrderedFields(countryCode);
        setCountry(countryReturn);
        setFormatter(formatter);
        setOrderedFields(fields);
      } catch (error) {
        console.error('Error fetching country:', error);
        setCountry(null);
        setFormatter(null);
      }
    };

    if (countryCode) {
      fetchCountry();
    }
  }, [locale, countryCode, i18n]);

  return { country, formatter, orderedFields, customOrderedFields };
};
